import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/styles/title';

// components
import VendorDirectoryAccordion from '../components/vendor-directory-accordion';

// styled components
import VendorDirectoryStyles from '../components/styles/vendor-directory-style';

// data
import mediaPackage from '../data/IOLCF-Media-Package-2022.pdf';

const VendorDirectory = () => (
    <Layout>
        <SEO title="Vendor Directory" />
        <Title>Vendor Directory</Title>

        <VendorDirectoryStyles>
            <div className="Accord__container">
                <VendorDirectoryAccordion />
            </div>
            <div className="BecomeVendor__container">
                <h1>Become a Vendor</h1>
                <p className="BecomeVendor__message">
                    We offer 3 affordable advertising packages for enhancing
                    your exposure. These packages include Toga Times magazine,
                    IOLCF Website, IOLCF e-Newsletter, IOLCF webinars, Board of
                    Directors access, and much more.
                </p>
                <a
                    className="JoinNow__button"
                    href={mediaPackage}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                >
                    View Media Package
                </a>
            </div>
        </VendorDirectoryStyles>
    </Layout>
);

export default VendorDirectory;
